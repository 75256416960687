<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
<div class="row justify-content-center">
  <div class="col-12 col-lg-8 px-5">
<h2 class="doc-title">Website Acceptable Use Policy</h2>
          <!-- <h3 class="doc-sub-title">Website Acceptable Use Policy</h3> -->
          
<p class="doc-pera">
  
This acceptable use policy sets out the terms between you and us under which
you may access our website www.fuse.gold (our website). This acceptable use
policy applies to all users of, and visitors to, our website.
</p>
<p class="doc-pera">
  Your use of our website means that you accept, and agree to abide by, all the
policies in this acceptable use policy, which supplement our terms of website use.
</p>
<p class="doc-pera">
  
Our Website is used, owned and operated by Fuse.Gold Limited(&quot;We&quot;). We are a
private company limited by shares registered in Gibraltar under company number
121092 and have our registered office at 28 Irish Town, GX11 1AA, Gibraltar.
</p>
<p class="doc-pera">
To contact us, please email info@fuse.gold.

</p>
<p class="doc-pera">
  <b>
PROHIBITED USES
  </b>
</p>
<p class="doc-pera">
You may use our website only for lawful purposes. You may not use our website:

</p>
<p class="doc-pera"></p>
<ul>
  
  <li>
    <p class="doc-pera">
  In any way that breaches any applicable local, national or international law or
regulation.    
    </p>
  </li>
  <li>
    <p class="doc-pera">
In any way that is unlawful or fraudulent or has any unlawful or fraudulent
purpose or effect.
    </p>
  </li>
  <li>
    <p class="doc-pera">
· For the purpose of harming or attempting to harm minors in any way.

    </p>
  </li>
  <li>
    <p class="doc-pera">
To transmit, or procure the sending of, any unsolicited or unauthorised
advertising or promotional material or any other form of similar solicitation
(spam).
    </p>
  </li>
  <li>
    <p class="doc-pera">
To knowingly transmit any data, send or upload any material that contains
viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware,
adware or any other analogous harmful programs or similar computer code
designed to adversely affect the operation of any computer software or
hardware.
    </p>
  </li>
  </ul>
<p class="doc-pera">
You also agree:
</p>
<ul>
<li>
    <p class="doc-pera">
Not to reproduce, duplicate, copy or re-sell any part of our website in
contravention of the provisions of our terms of website use.
    </p>
  </li>
  <li>
    <p class="doc-pera">
   Not to access without authority, interfere with, damage or disrupt:
<ul>
  <li><p class="doc-pera">any part of our website</p></li>
  <li><p class="doc-pera"> any equipment or network on which our website is stored </p></li>
  <li><p class="doc-pera">any software used for or in connection with the provision of our
website; and/or</p></li>
  <li><p class="doc-pera">any equipment or network or software owned or provided by any third
party.</p></li>
  
</ul>
    </p>
  </li>
</ul>
<p class="doc-pera">
<b>CHANGES TO THE ACCEPTABLE USE POLICY</b>
</p>
<p class="doc-pera">
We may revise this acceptable use policy at any time by amending this page. You
are expected to check this page from time to time to take notice of any changes
we make, as they are legally binding on you. Some of the provisions contained in
this acceptable use policy may also be superseded by provisions or notices
published elsewhere on our website.
    </p>
<p class="doc-pera"> <b> FAILURE TO ADHERE TO POLICY </b> </p>
<p class="doc-pera">
Any person or persons that are deemed to not be adhering to this policy will be reported to the
police and appropriate authorities.
</p>


  </div>
</div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "WAUP",
}
</script>

<style>

</style>